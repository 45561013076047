@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');


/* body,html{
    width: 100%;
    height:100%;
}  */

/**************** Inicio layout página de login *****************/
/*
Div principal dos componentes
dos inputs do login
*/
.container_main_padrao{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;   
}


/*
Div principal dos componentes
da imagem do logo
*/
.container_padrao{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;    
}
/* **********************************COnfiguração Padrão das Telas**************** */
.container_main_padrao-tela{
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;  
    background-color: #4D3EFC;  
    /* background-color: #FFF;  */
}

.container_padrao-tela{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: #FFF;
    width: 50%;   
}

.container_padrao header, .container_padrao-tela header{
    top: 0px;
    width: 100%;
    height: 15%;
    background-color: #e2d4ee;
}

.container_padrao label, .container_padrao-tela label{
    font-size: 1vmax;
    font-weight: bolder;
    font-family: 'Exo 2', sans-serif;
    margin-top: 10px;
}

.container_padrao h3, .container_padrao-tela h3{
    text-align: center;
}

.container_padrao h1:hover, .container_padrao-tela h1:hover{
    font-size: 30px;
    transition: .4s;
}

.container_padrao header h1, .container_padrao header h3, .container_padrao-tela header h1, .container_padrao-tela header h3{
    color: #4D3EFC;
    font-family: 'Exo 2', sans-serif;
}

.container_padrao .input-padrao, .container_padrao-tela .input-padrao{
    width: 60%;
    height: 5%;
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 3%;
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    text-align: center;
    
}
/* ********************************* Campos ***************** */
.campos{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.descricao{
    font-size: 2vmax;
    font-family: 'Exo 2', sans-serif;
    text-align: center;
    
}

.link{
    text-decoration: none;
    color: #4D3EFC;
}

.botao-padrao, #salvar{
    width: 35%;
    height: 7%;
    background-color: #4D3EFC;
    color: #FFF;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    border-radius: 25px 25px;
    margin: 5px 0;
}

.botao-padrao:hover, #salvar:hover{
    width: 40%;
    font-size: 20px;
    transition: .5s;
}

#botao-pagar{
    width: 35%;
    background-color: #198754;
    height: 7%;
    color: #FFF;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Exo 2', sans-serif;
    border-radius: 25px 25px;
    margin: 5px 0;
}

#botao-pagar:hover{
    width: 40%;
    font-size: 20px;
    transition: .5s;
    background-color: #157347;
}

.container_imagem{
    display: flex; 
    flex-direction: column;
    color: #FFF;
    font-family: 'Exo 2', sans-serif;
    font-size: 25px;
    width: 65%;
    height:100%; 
    align-items: center; 
    justify-content: center;
    background-color: #4D3EFC; 
}

.container_imagem img{
    max-width: 70%;
} 

/* ******************* Layout cadastro usuários ***************** */

.descricao-cad-usuario-h3{
    font-size: 1vmax;
}

/* ******************* Layout do cadastro do Banco / Receita  ***************** */
.container-cadBanco, .container-cadReceita, .container-cadReceita, .container-cadDespesa{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100vh; 
    /* width: 100%; */
}

.divCadBanco, .divCadReceita, .divCadDespesa{
    width: 90%;
    height: 80vh;
    margin: auto 5%; 
    align-items: center;
}

.cadBanco, .cadReceita, .cadDespesa{
    display: flex;
    flex-direction: column;
    /* border: #4D3EFC solid 1px; */
    /* border-radius: 25px; */
    /* width: 100%; */
    height: 100%;
    align-items: center; 

}

.adiciona-banco, .adiciona-receita {
    width: 61%;
    height: 6%;
    margin-top: 30px;
    /* border: #000 solid 1px; */
}

.adiciona-banco select, .adiciona-receita select{
    width: 90%;
    height: 100%;    
}


.adiciona-banco button, .adiciona-receita button{
    width: 10%;
    height: 100%;
    background-color: #4D3EFC;
    color: #FFF;
    font-weight: bolder;
}


.cadBanco input, .cadReceita input{
    width: 60%;
    height: 5%;
    margin-top: 10px;
}
 /* **** Configurações específicas de cadastro de Banco ****   */
.raio_group{
    border: #4D3EFC solid 1px;
    background-color: #e2d4ee;
    width: 60%;
    height: 30%;
    margin: 5px auto;

}

/* .raio_group label{
    font-family: 'Exo 2', sans-serif;
    font-size: 15px;
} */

/* .raio_group label input:checked{
    width: 50px;
    transition: .5s;
} */

.raio_group label input{
    width: 20px;
    height: 10%;
    margin-top: 20px;
}



/* *************************** Menu **************************** */
.flex-container-menu{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: row;
    justify-content: center;
    /* background-color: #4D3EFC; */
    background-color: #FFF;
}

.flex-container-menu a{
    width: 200px; 
    text-align: center;
    text-decoration: none;
    color: #000; 
    /* color: #FFF; */
    margin: 5px 0 5px 0;
}

.flex-container-menu a:hover{
    color: #FFF;
    background-color: #4D3EFC;
    font-weight: bolder;
}

/* Movimentação bancária */

.transferenciaEntreContas{
    width: 100%;
    height: 30%;
}

@media (max-width:600px){
	
}
/* *************************** Home **************************** */

.gastos{
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    width: 100%;
}

.despesaVencendo{
    border: #4D3EFC solid 1px;
    width: 45%;
    margin: 2px auto 0 auto;
}

.nomeUsuario{
    background-color: #e2d4ee;
    
}
.saldos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.saldo{
    width: 33%;
    border: #4D3EFC solid 1px;
}
.Gasto{
    background-color: turquoise;
}
.Evolucao{
    background-color: coral;
}

/* *************************** Relatorios **************************** */

.menu-relatorios{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: row;
    justify-content: center;
    
}

.menu-relatorios li{
    /* border: #000 solid 1px; */
    width: 200px;
    text-align: center; 
}

.menu-relatorios li:hover{
    color: #FFFF;
    background-color: #4D3EFC;
    font-weight: bolder;
    
}

.menu-guia{
    color: #4D3EFC;
    background-color: #e2d4ee;
    font-weight: bolder;
}

.body-relatorio{
    background-color: #e2d4ee;
    width: 100%;
    height: 100vh;
    margin-top: -16px;
}

.filtros{
    padding: 15px;
    text-align: center;
}

.filtros .pesquisar{
    width: 38%;
    height: 15%;
    margin-right: 1%;
}
.imgPesquisa{
    height: 28px;
    margin-left: 10px;
    cursor: pointer;
}

.filtros Button{
    margin-right: 1%;
}



